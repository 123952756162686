import { useContext } from "react";
import { ThemeContext } from "../Theme";

const useTheme = () => {
  const { theme } = useContext(ThemeContext);

  return theme;
};

export default useTheme;
