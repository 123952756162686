import { Component } from "react";
import LanguageConsumer from "./Language/LanguageConsumer";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error({ error, errorInfo });
    /* logErrorToMyService(error, errorInfo); */
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="general-error">
          <LanguageConsumer element="h1" path="errors.generalErrorBoundary" />
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
